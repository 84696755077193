<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
         <DocumentsChecklist
          v-if="checklist !== null"
          :checklist="checklist"
          @onUpdateCheckList="updateChecklist"
         />
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';
import DocumentsChecklist from '@/components/ApplicationSections/Components/DocumentsChecklist.vue';

export default {
  name: 'ChecklistSection',

  components: {
    BaseSection,
    DocumentsChecklist,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Object,
      default() {
        return {};
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isSectionVisible: false,

      initialChecklist: {},
      checklist: null,
    };
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialChecklist = await clone(this.existedData);
    this.checklist = await clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    updateChecklist(checklist) {
      this.checklist = checklist;
    },
  },
};
</script>
