<template>
  <div>
    <v-expansion-panels accordion focusable>
      <v-expansion-panel
        v-for="(application, applicationIdx) in activeSubApplications"
        :key="application.id"
      >
        <v-expansion-panel-header>
          {{ fullName(application, applicationIdx) }}
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="commonDoc in commonDocs"
                :key="commonDoc"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ commonDoc }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="editChecklist?.apps?.[application.id]">
                  <v-checkbox
                    v-model="editChecklist.apps[application.id]"
                    :value="commonDoc"
                    @click.prevent="updateDocument"
                    :disabled="FIELDS_DISABLED"
                  />
                </v-list-item-action>
              </v-list-item>

              <v-divider v-if="isExtraDocsExist(application.id)" />

              <v-list-item
                v-for="extra in extraDocs(application.id)" :key="extra"
              >

                <v-list-item-content>
                  <v-list-item-title>
                    {{ extra }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    v-model="editChecklist.apps[application.id]"
                    :value="extra"
                    @click.prevent="updateDocument"
                    :disabled="FIELDS_DISABLED"
                  />
                </v-list-item-action>

              </v-list-item>
            </v-list-item-group>
          </v-list>

        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="documentLiabilities.length > 0">
        <v-expansion-panel-header>
          Liabilities
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="(liability) in documentLiabilities"
                :key="liability.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                   {{ liability.type }} bank statements for the last 3 months
                  </v-list-item-title>
                  {{ liability.ownership }} / {{ liability.creditor }} / ${{ liability.balance }}
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    v-model="editChecklist.liabilities"
                    :value="liability.id"
                    :disabled="FIELDS_DISABLED"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel v-if="documentCreditCards.length > 0">
        <v-expansion-panel-header>
          Credit cards
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="creditCard in documentCreditCards"
                :key="creditCard.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    Credit card statements for the last 3 months
                    ({{ creditCard.ownership }} /
                    {{ creditCard.creditor }} /
                    ${{ creditCard.limit }})
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    v-model="editChecklist.creditCards"
                    :value="creditCard.id"
                    :disabled="FIELDS_DISABLED"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-if="documentRental.length > 0">
        <v-expansion-panel-header>
          Rental incomes
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="rental in documentRental"
                :key="rental.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ rental.type }} Agrrement or
                    {{ rental.type }} appraisal form for  {{ rental.address }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-checkbox
                    v-model="editChecklist.rental"
                    :value="rental.id"
                    :disabled="FIELDS_DISABLED"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel>
        <v-expansion-panel-header>
          Other documents
        </v-expansion-panel-header>
        <v-expansion-panel-content >
          <v-list dense flat>
            <v-list-item-group>
              <v-list-item
                v-for="other in otherListItems"
                :key="other.id"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    {{ other.value }}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action v-if="editChecklist.other">
                  <v-checkbox
                    v-model="editChecklist.other"
                    :value="other.value"
                    :disabled="FIELDS_DISABLED"
                    @click.prevent="updateDocument"
                  />
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { APPLICATION_STATUSES } from '../../../models/helpers/consts';
import { clone } from '@/utils/object';

export default {
  name: 'DocumentsChecklist',

  props: {
    checklist: {
      type: Object,
    },
  },

  emits: ['onUpdateCheckList'],

  data() {
    return {
      commonDocs: [
        'Passport',
        'Visa',
        'Personal bank statements for the last 3 months',
        'Sign Declaration form (attached)',
      ],

      payeDocs: [
        '3 Payslips',
        'Employment agreement or employment letter',
      ],

      selfDocs: [
        'Financial report for the last 2 years',
        'IR3 and IR4',
        'Business bank statements for the last 6 months',
      ],

      otherListItems: [
        {
          id: 0,
          value: 'Sale & Purchase Agreement',
        },
        {
          id: 1,
          value: 'Authority and declaration',
        },
        {
          id: 2,
          value: 'Claw back form',
        },
        {
          id: 3,
          value: 'Loan profile',
        },
      ],

      editChecklist: {
        apps: {},
        rental: [],
        creditCards: [],
        liabilities: [],
        other: [],
      },
    };
  },

  async created() {
    this.editChecklist = await clone(this.checklist);
    await this.generateApplicantDocs();
  },

  watch: {
    editChecklist: {
      handler() {
        this.$emit('onUpdateCheckList', this.editChecklist);
      },

      deep: true,
      immediate: true,
    },

    SUB_APPLICATIONS: {
      async handler() {
        await this.generateApplicantDocs();
      },

      deep: true,
      immediate: true,
    },
  },

  computed: {
    ...mapGetters(['APPLICATION', 'SUB_APPLICATIONS', 'FIELDS_DISABLED']),

    documentLiabilities() {
      return this.APPLICATION?.data?.liabilities?.items;
    },

    documentCreditCards() {
      return this.APPLICATION?.data?.liabilities?.credit_cards;
    },

    documentRental() {
      return this.APPLICATION?.data?.incomes?.rental;
    },

    activeSubApplications() {
      const isActive = (application) => application.status !== APPLICATION_STATUSES.draft;

      return this.SUB_APPLICATIONS.filter(isActive);
    },
  },

  methods: {
    fullName(applicant, index) {
      const applicantData = applicant?.data?.personal_details;

      const firstName = applicantData?.first_name;
      const lastName = applicantData?.last_name;

      const fullName = `${firstName} ${lastName}`;

      if (fullName !== ' ') {
        return fullName;
      }

      return `Applicant #${index + 1}`;
    },

    extraDocs(applicationId) {
      let result = [];

      const currentSubApplication = this.SUB_APPLICATIONS.find((item) => item.id === applicationId);

      const employments = currentSubApplication?.data?.employments;

      const asPaye = employments.filter((employment) => employment.income_source === 'Salary/Wages' && employment.current === true);
      const asSelf = employments.filter((employment) => employment.income_source === 'Self Employed' && employment.current === true);

      const isAsPayeExist = Boolean(asPaye?.length > 0);
      if (isAsPayeExist) {
        result = result.concat(this.payeDocs);
      }

      const isAsSelfExist = Boolean(asSelf?.length > 0);
      if (isAsSelfExist) {
        result = result.concat(this.selfDocs);
      }

      return result;
    },

    isExtraDocsExist(applicationId) {
      const extraDocs = this.extraDocs(applicationId);
      const isExtraDocsExist = Boolean(extraDocs?.length > 0);

      return isExtraDocsExist;
    },

    generateApplicantDocs() {
      const generateDocs = (subApplication) => {
        this.editChecklist.apps[subApplication.id] = [];

        const fillAppsDoc = (doc) => {
          this.editChecklist.apps[subApplication.id].push(doc);
        };

        this.APPLICATION?.data?.checklist?.apps?.[subApplication.id]?.forEach(fillAppsDoc);
      };

      this.activeSubApplications.map(generateDocs);
    },

    async updateDocument() {
      this.editChecklist = { ...this.editChecklist };
    },
  },
};
</script>
