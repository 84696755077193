var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-expansion-panels', {
    attrs: {
      "accordion": "",
      "focusable": ""
    }
  }, [_vm._l(_vm.activeSubApplications, function (application, applicationIdx) {
    return _c('v-expansion-panel', {
      key: application.id
    }, [_c('v-expansion-panel-header', [_vm._v(" " + _vm._s(_vm.fullName(application, applicationIdx)) + " ")]), _c('v-expansion-panel-content', [_c('v-list', {
      attrs: {
        "dense": "",
        "flat": ""
      }
    }, [_c('v-list-item-group', [_vm._l(_vm.commonDocs, function (commonDoc) {
      var _vm$editChecklist, _vm$editChecklist$app;

      return _c('v-list-item', {
        key: commonDoc
      }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(commonDoc) + " ")])], 1), (_vm$editChecklist = _vm.editChecklist) !== null && _vm$editChecklist !== void 0 && (_vm$editChecklist$app = _vm$editChecklist.apps) !== null && _vm$editChecklist$app !== void 0 && _vm$editChecklist$app[application.id] ? _c('v-list-item-action', [_c('v-checkbox', {
        attrs: {
          "value": commonDoc,
          "disabled": _vm.FIELDS_DISABLED
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.updateDocument.apply(null, arguments);
          }
        },
        model: {
          value: _vm.editChecklist.apps[application.id],
          callback: function callback($$v) {
            _vm.$set(_vm.editChecklist.apps, application.id, $$v);
          },
          expression: "editChecklist.apps[application.id]"
        }
      })], 1) : _vm._e()], 1);
    }), _vm.isExtraDocsExist(application.id) ? _c('v-divider') : _vm._e(), _vm._l(_vm.extraDocs(application.id), function (extra) {
      return _c('v-list-item', {
        key: extra
      }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(extra) + " ")])], 1), _c('v-list-item-action', [_c('v-checkbox', {
        attrs: {
          "value": extra,
          "disabled": _vm.FIELDS_DISABLED
        },
        on: {
          "click": function click($event) {
            $event.preventDefault();
            return _vm.updateDocument.apply(null, arguments);
          }
        },
        model: {
          value: _vm.editChecklist.apps[application.id],
          callback: function callback($$v) {
            _vm.$set(_vm.editChecklist.apps, application.id, $$v);
          },
          expression: "editChecklist.apps[application.id]"
        }
      })], 1)], 1);
    })], 2)], 1)], 1)], 1);
  }), _vm.documentLiabilities.length > 0 ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Liabilities ")]), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-list-item-group', _vm._l(_vm.documentLiabilities, function (liability) {
    return _c('v-list-item', {
      key: liability.id
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(liability.type) + " bank statements for the last 3 months ")]), _vm._v(" " + _vm._s(liability.ownership) + " / " + _vm._s(liability.creditor) + " / $" + _vm._s(liability.balance) + " ")], 1), _c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "value": liability.id,
        "disabled": _vm.FIELDS_DISABLED
      },
      model: {
        value: _vm.editChecklist.liabilities,
        callback: function callback($$v) {
          _vm.$set(_vm.editChecklist, "liabilities", $$v);
        },
        expression: "editChecklist.liabilities"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.documentCreditCards.length > 0 ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Credit cards ")]), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-list-item-group', _vm._l(_vm.documentCreditCards, function (creditCard) {
    return _c('v-list-item', {
      key: creditCard.id
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" Credit card statements for the last 3 months (" + _vm._s(creditCard.ownership) + " / " + _vm._s(creditCard.creditor) + " / $" + _vm._s(creditCard.limit) + ") ")])], 1), _c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "value": creditCard.id,
        "disabled": _vm.FIELDS_DISABLED
      },
      model: {
        value: _vm.editChecklist.creditCards,
        callback: function callback($$v) {
          _vm.$set(_vm.editChecklist, "creditCards", $$v);
        },
        expression: "editChecklist.creditCards"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _vm.documentRental.length > 0 ? _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Rental incomes ")]), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-list-item-group', _vm._l(_vm.documentRental, function (rental) {
    return _c('v-list-item', {
      key: rental.id
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(rental.type) + " Agrrement or " + _vm._s(rental.type) + " appraisal form for " + _vm._s(rental.address) + " ")])], 1), _c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "value": rental.id,
        "disabled": _vm.FIELDS_DISABLED
      },
      model: {
        value: _vm.editChecklist.rental,
        callback: function callback($$v) {
          _vm.$set(_vm.editChecklist, "rental", $$v);
        },
        expression: "editChecklist.rental"
      }
    })], 1)], 1);
  }), 1)], 1)], 1)], 1) : _vm._e(), _c('v-expansion-panel', [_c('v-expansion-panel-header', [_vm._v(" Other documents ")]), _c('v-expansion-panel-content', [_c('v-list', {
    attrs: {
      "dense": "",
      "flat": ""
    }
  }, [_c('v-list-item-group', _vm._l(_vm.otherListItems, function (other) {
    return _c('v-list-item', {
      key: other.id
    }, [_c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(other.value) + " ")])], 1), _vm.editChecklist.other ? _c('v-list-item-action', [_c('v-checkbox', {
      attrs: {
        "value": other.value,
        "disabled": _vm.FIELDS_DISABLED
      },
      on: {
        "click": function click($event) {
          $event.preventDefault();
          return _vm.updateDocument.apply(null, arguments);
        }
      },
      model: {
        value: _vm.editChecklist.other,
        callback: function callback($$v) {
          _vm.$set(_vm.editChecklist, "other", $$v);
        },
        expression: "editChecklist.other"
      }
    })], 1) : _vm._e()], 1);
  }), 1)], 1)], 1)], 1)], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }